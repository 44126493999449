import { Button, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import useAuth from '../../../hooks/useAuth';
import { InputField } from '../../form_fields';
import * as Yup from 'yup';
import { USER_RECORD_TYPES } from '../../../constants';
import { isInstaller } from '../../../utils/helpers';

const UserProfileValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(255)
    .min(1, 'Please enter a first name with at least 1 character.')
    .label('First Name')
    .required(),
  last_name: Yup.string()
    .max(255)
    .min(1, 'Please enter a last name with at least 1 character.')
    .label('Last Name')
    .required(),
});

export const UserProfileForm = () => {
  const { user, account, updateUserData } = useAuth();
  const recordType = user.record_type;
  async function _handleSubmit(values, formikBag) {
    try {
      await updateUserData(values);
      toast.success('Profile Information Updated!');
    } catch (err) {
      if (err.data) return formikBag.setErrors(err.data);
      toast.error('Something went wrong!');
    }
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={UserProfileValidationSchema}
      initialValues={{
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        business_name:
          recordType === USER_RECORD_TYPES.BORROWER
            ? user.borrower_business_name
            : account.business_name,
        dba:
          recordType === USER_RECORD_TYPES.BORROWER
            ? account.dba_if_applicable
            : account.installer_dba_if_applicable,
        position_title: user.position_title,
        account_type:
          recordType === USER_RECORD_TYPES.BORROWER
            ? null
            : user.installer_role,
      }}
      onSubmit={_handleSubmit}
    >
      {({ isSubmitting, isValid, dirty, resetForm }) => (
        <Form>
          <Grid container rowSpacing={2.25} columnSpacing={3}>
            <Grid item xs={12} sm={6}>
              <InputField label="First Name" fullWidth name={'first_name'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField label="Last Name" fullWidth name={'last_name'} />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Email Address"
                fullWidth
                disabled
                name={'email'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Position Title"
                fullWidth
                disabled
                name={'position_title'}
              />
            </Grid>
            {isInstaller(user) && (
              <Grid item xs={12}>
                <InputField
                  label="Role"
                  fullWidth
                  disabled
                  name={'account_type'}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <InputField
                label="Business Name"
                fullWidth
                disabled
                name={'business_name'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField label="DBA" fullWidth disabled name={'dba'} />
            </Grid>
            <Grid item xs={12} textAlign={'end'}>
              {dirty && (
                <Button variant="text" onClick={resetForm}>
                  Cancel
                </Button>
              )}
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!dirty || !isValid}
                loading={isSubmitting}
              >
                Save Changes
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
