import React, { forwardRef } from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.input,
    color: (theme as any).palette.text.primary,
    fontSize: (theme as any).typography.pxToRem(16),
    borderRadius: '.75rem',
    border: '1px solid rgba(219, 224, 233, 1)',
    padding: '.75rem .875rem',
    textAlign: 'center',
  },
  arrow: {
    color: theme.palette.background.input,
    '&::before': {
      border: '1px solid rgba(219, 224, 233, 1)',
    },
  },
}));

export const CustomTooltip = forwardRef<HTMLElement, TooltipProps>(
  function CustomTooltip(props, ref) {
    const classes = useStyles();
    return (
      <MuiTooltip classes={classes} ref={ref} {...props}>
        {props.children}
      </MuiTooltip>
    );
  }
);
