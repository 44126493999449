import { isEqual, isObject, transform } from 'lodash';
import { AlertColor } from '@mui/material';
import {
  FundedProject,
  Milestone,
  ProductFamily,
  Contact,
} from '../../../../types/api';
import {
  DSCR_AVG_LIMIT_1,
  DSCR_AVG_LIMIT_2,
  DSCR_MIN_LIMIT_1,
  DSCR_MIN_LIMIT_2,
  INSTALLER_PROGRAM_TYPES,
  ACCOUNT_TYPES,
} from '../constants';
import { currencyFormatter } from './formatters';

export const difference = (origObj, newObj) => {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0;
    return transform(newObj, function (result: any, value, key) {
      if (!isEqual(value, origObj[key])) {
        const resultKey = Array.isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  }
  return changes(newObj, origObj);
};

/**
 * Recieves a sequence of callables, returns a function calling each with the given arguments
 */
export const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn?.(...args));

/**
 * Recurseively traverse object and return an array of paths where callback returns true
 */
export const traverse = (
  obj: any,
  callback: (value: any, key: string) => boolean,
  path: string[] = []
) => {
  return Object.entries(obj).reduce<string[]>((acc, [key, value]) => {
    if (callback(value, key)) {
      acc.push([...path, key].join('.'));
    }
    if (typeof value === 'object' && value !== null) {
      return [...acc, ...traverse(value, callback, [...path, key])];
    }
    return acc;
  }, []);
};

/**
 * Get the value of a nested object property by path, where path is a string of keys separated by dots
 */
export const getAtPath = (obj, path, defaultValue = null) => {
  const pathArray = path.split('.');
  return pathArray.reduce((acc, key) => {
    if (acc && acc[key]) {
      return acc[key];
    }
    return defaultValue;
  }, obj);
};

/**
 * Shared by LoanInformation.tsx and PriceMyProjectPage.tsx
 * filters productList by loanType
 * extracts unique combinations of loan term years and amortization years
 * returns the results sorted by loan term years and amortization years ascending order
 */

interface LoanYearsItem {
  label: string;
  value: string;
  term: number;
  amort: number;
}

export const getLoanYearsOptions = (
  productList,
  loanType,
  isLoanInformation = false
) => {
  let filtered;

  // special filtering for LoanInformation page
  if (isLoanInformation) {
    filtered = productList
      // If the selected loan type is Standard, allow both standard and premium products
      .filter(({ productFamily }) =>
        loanType === ProductFamily.STANDARD
          ? productFamily === ProductFamily.STANDARD ||
            productFamily === ProductFamily.PREMIUM
          : productFamily === loanType
      );
  } else {
    filtered = productList.filter(
      ({ productFamily }) => productFamily === loanType
    );
  }

  const newList: LoanYearsItem[] = [];
  for (const product of filtered) {
    // combine loanTermYears and amortizationYears into sortable string, e.g. '1020' or '0500'
    const label =
      `${product.loanTermYears} years` +
      (product.amortizationYears &&
      product.loanTermYears !== product.amortizationYears
        ? ` / ${product.amortizationYears} years`
        : '');
    const value =
      product.loanTermYears.toString().padStart(2, '0') +
      (product.amortizationYears
        ? product.amortizationYears.toString().padStart(2, '0')
        : '00');
    // note: if there's no amortizationYears value, amort will be null for isLoanInformation = true and 0 for isLoanInformation = false
    newList.push({
      label: label,
      value: value,
      term: product.loanTermYears,
      amort: product.amortizationYears,
    });
  }

  // remove duplicates and sort by year/amort
  const deDuped = newList
    .filter(
      (obj, index, self) =>
        index === self.findIndex((o) => o.label === obj.label)
    )
    .sort((a, b) => parseInt(a.value) - parseInt(b.value));

  return deDuped;
};

export const isSpecialState = (state: string) =>
  ['Colorado', 'Illinois'].includes(state);

export const isStateWithREC = (state: string) => {
  return [
    'Illinois',
    'Colorado',
    'New Jersey',
    'Washington, D.C.',
    'Maryland',
    'Ohio',
    'Pennsylvania',
  ].includes(state);
};

const poorDscrRecStatesMessage = (account) =>
  `Based on the values entered, the loan's solar economics do not
  pass Sunstone's thresholds and the loan is likely to be declined. However,
  calculations for states with RECs (Illinois, Colorado, New Jersey, Washington, D.C.,
  Maryland, Ohio, Pennsylvania) are still in BETA. Please contact your account manager (${account?.owner.name}, ${account?.owner.email}) for
  the most accurate prequalification.`;

const poorDscrMessage = (account, systemLocation) =>
  isStateWithREC(systemLocation)
    ? poorDscrRecStatesMessage(account)
    : `Based on the values entered, the loan's solar economics do not
  pass Sunstone's thresholds and the loan is likely to be declined.
  Please speak to your Account Manager (${account?.owner.name}, ${account?.owner.email})
  about possible remedies, including adjusting the loan type or amount.`;

const tenYearDscrMessage = (account) =>
  `Based on the values entered, the loan's solar economics do not
  pass Sunstone's thresholds and the loan is likely to be declined.
  However, this loan does meet the threshold requirements for a loan with a MAXIMUM 10 year term.
  Please update the loan term above and select a term of 10 years or less (includes the 10/20 loan).
  Please speak to your Account Manager (${account?.owner.name}, ${account?.owner.email}) if you have any questions.`;

const goodDscrMessage = `Based on the values entered, the loan's solar economics pass Sunstone's thresholds. Please proceed and submit the application.`;

/**
 * Shared by SolarProjectInformation.tsx and SolarEconCalculator.tsx
 * Returns a DSCR result message based on loan term, DSCR score, and system location
 **/
export const getDSCRMessage = (
  solarDSCR,
  loanTerm,
  account,
  systemLocation
): [AlertColor, string] => {
  const avgDSCR = solarDSCR.reduce((l, r) => l + r) / solarDSCR.length;
  const minDSCR = Math.min(...solarDSCR);

  let alertType: AlertColor;
  if (loanTerm > 10) {
    if (avgDSCR >= DSCR_AVG_LIMIT_1 && minDSCR >= DSCR_MIN_LIMIT_1) {
      return ['success', goodDscrMessage];
    } else if (avgDSCR >= DSCR_AVG_LIMIT_2 && minDSCR >= DSCR_MIN_LIMIT_2) {
      return ['warning', tenYearDscrMessage(account)];
    } else {
      return ['error', poorDscrMessage(account, systemLocation)];
    }
  } else {
    if (avgDSCR >= DSCR_AVG_LIMIT_2 && minDSCR >= DSCR_MIN_LIMIT_2) {
      return ['success', goodDscrMessage];
    } else {
      return ['error', poorDscrMessage(account, systemLocation)];
    }
  }
};

export const getMilestoneProgramLimit = (
  project: FundedProject,
  milestone: Milestone
) => {
  const hasMilestoneProgram =
    project.installer_program?.type ===
    INSTALLER_PROGRAM_TYPES.MILESTONE_PROGRAM;

  let maximumRequest = milestone.max_allowed_amount ?? 0;
  if (maximumRequest != null) {
    maximumRequest = parseFloat(maximumRequest.toString());
  }
  let availableBelowMaxAllowed = false;
  let limitReached = false;
  let available = project.installer_program?.amount_of_available_funds ?? 0;
  if (available != null) {
    available = parseFloat(available.toString());
    // should never happen, but this will prevent us from confusing our users
    if (available < 0) {
      available = 0;
    }
  } else {
    available = 0;
  }

  const programLimit = project.installer_program?.funding_limit ?? 0;

  const nearingLimit = available / programLimit <= 0.25;

  if (
    hasMilestoneProgram &&
    available != undefined &&
    available < maximumRequest
  ) {
    maximumRequest = available;
    availableBelowMaxAllowed = true;
    if (available === 0) {
      limitReached = true;
    }
  }

  const maximumRequestFormatted = currencyFormatter.format(maximumRequest);
  const totalAvailable = currencyFormatter.format(available);

  return {
    hasMilestoneProgram,
    maximumRequest,
    maximumRequestFormatted,
    nearingLimit,
    availableBelowMaxAllowed,
    limitReached,
    totalAvailable,
  };
};

export const verifyType = (
  value: Contact | string | null | undefined,
  targetAccountType: (typeof ACCOUNT_TYPES)[keyof typeof ACCOUNT_TYPES]
) => {
  if (value === null || value === undefined) return false;
  const accountType = typeof value === 'string' ? value : value.account.type;
  return accountType === targetAccountType;
};

export const isInstaller = (value: Contact | string | null | undefined) => {
  return verifyType(value, ACCOUNT_TYPES.INSTALLER);
};

export const isConsultant = (value: Contact | string | null | undefined) => {
  return verifyType(value, ACCOUNT_TYPES.CONSULTANT);
};
