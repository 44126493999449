import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/authentication/AuthGuard';
import ApplicationLayout from './components/dashboard/AppLayout';
import GuestGuard from './components/authentication/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import { Navigate } from 'react-router';
import {
  ACCOUNT_TYPES,
  DEBUG_SETTINGS_PATH,
  NEW_APPLICATION_PATH,
  USER_RECORD_TYPES,
  USER_SETTINGS_PATH,
} from './constants';
import { UserSettings } from './components/account/userSettings/UserSettings';
import {
  DebugForm,
  IS_DEV_OR_STAGING,
} from './components/account/userSettings/DebugForm';
import InstallerApplicationSubmitted from './pages/InstallerApplicationSubmitted';
import NewBorrowerForm from './components/dashboard/installers/NewBorrowerForm/NewBorrowerForm';
import Referral from './components/utility_api/Referral';
const LOGIN_REDIRECT_PATH = '/authentication/login';

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery'))
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset'))
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/Register'))
);
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/VerifyCode'))
);
const CheckEmail = Loadable(
  lazy(() => import('./pages/authentication/CheckEmail'))
);
const VerificationRequired = Loadable(
  lazy(() => import('./pages/authentication/VerificationRequired'))
);

// Borrower Loan pages

const LoanBrowse = Loadable(lazy(() => import('./pages/dashboard/LoanBrowse')));
const BorrowerDocuments = Loadable(
  lazy(() => import('./pages/dashboard/BorrowerDocuments'))
);
const ApplicationReviewPage = Loadable(
  lazy(() => import('./pages/dashboard/loan_detail/ApplicationReviewPage'))
);

const ApplicationWelcomePage = Loadable(
  lazy(() => import('./pages/dashboard/loan_detail/ApplicationWelcome'))
);
const ApplicationPage = Loadable(
  lazy(() => import('./pages/dashboard/loan_detail/ApplicationPage'))
);
const ApplicationLoader = Loadable(
  lazy(() => import('./pages/dashboard/loan_detail/ApplicationLoader'))
);

// Installer Pages

const BorrowerBrowse = Loadable(
  lazy(() => import('./pages/dashboard/BorrowerBrowse'))
);

const ProjectDetails = Loadable(
  lazy(() => import('./pages/dashboard/ProjectDetails/ProjectDetails'))
);

const ResourcesLayout = Loadable(
  lazy(() => import('./pages/resources/ResourcePageLayout'))
);

const MarketingMaterials = Loadable(
  lazy(() => import('./pages/resources/MarketingMaterialsPage'))
);
const RateSheets = Loadable(
  lazy(() => import('./pages/resources/RateSheetsPage'))
);

// Change Order Components
const ChangeOrderLoader = Loadable(
  lazy(() => import('./pages/dashboard/ChangeOrder/ChangeOrderLoader'))
);

const ChangeOrder = Loadable(
  lazy(() => import('./pages/dashboard/ChangeOrder/ChangeOrder'))
);
// Loan Calculator
const LoanCalculator = Loadable(
  lazy(() => import('./pages/dashboard/LoanCalculator/LoanCalculator'))
);
// Pricing Calculator
const PriceMyProjectPage = Loadable(
  lazy(() => import('./pages/pricing-calculator/PriceMyProjectPage'))
);

const PriceMyProjectPageV1 = Loadable(
  lazy(() => import('./pages/pricing-calculator/PriceMyProjectPageV1'))
);

const LoanDetailsPage = Loadable(
  lazy(() => import('./pages/pricing-calculator/LoanDetails'))
);

const commonRoutes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'reset-password',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'verify',
        element: <VerifyCode />,
      },
      {
        path: 'verification-required',
        element: (
          <AuthGuard>
            <VerificationRequired />
          </AuthGuard>
        ),
      },
      {
        path: 'check-email',
        element: <CheckEmail />,
      },
      {
        path: '*',
        element: <Navigate to={LOGIN_REDIRECT_PATH} />,
      },
      {
        index: true,
        element: <Navigate to={LOGIN_REDIRECT_PATH} />,
      },
    ],
  },
  {
    path: USER_SETTINGS_PATH,
    element: (
      <AuthGuard>
        <ApplicationLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <UserSettings />,
      },
      {
        path: '*',
        element: <Navigate to={`/${USER_SETTINGS_PATH}/`} />,
      },
    ],
  },
  ...[
    IS_DEV_OR_STAGING
      ? {
          path: DEBUG_SETTINGS_PATH,
          element: (
            <AuthGuard>
              <ApplicationLayout />
            </AuthGuard>
          ),
          children: [
            {
              index: true,
              element: <DebugForm />,
            },
            {
              path: '*',
              element: <Navigate to={`/${DEBUG_SETTINGS_PATH}/`} />,
            },
          ],
        }
      : {},
  ],
  {
    path: 'partner',
    children: [
      {
        path: 'application-submitted',
        element: <InstallerApplicationSubmitted />,
      },
    ],
  },
  {
    path: 'utility-bill-authorization-referral',
    element: (
      <AuthGuard>
        <Referral />
      </AuthGuard>
    ),
  },
  { path: 'internal_error', element: <Navigate to={LOGIN_REDIRECT_PATH} /> },
  { path: '*', element: <Navigate to={LOGIN_REDIRECT_PATH} /> },
  { index: true, element: <Navigate to={LOGIN_REDIRECT_PATH} /> },
];

const borrowerRoutes = [
  {
    path: 'applications',
    children: [
      {
        index: true,
        element: <LoanBrowse />,
      },
      {
        path: ':loanId/*',
        element: <ApplicationLoader />,
        children: [
          {
            index: true,
            element: <ApplicationPage />,
          },
          {
            path: 'review',
            element: <ApplicationReviewPage />,
          },
          {
            path: 'welcome',
            element: <ApplicationWelcomePage />,
          },
          {
            path: '*',
            element: <Navigate to={'..'} />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to={LOGIN_REDIRECT_PATH} />,
      },
    ],
  },
  {
    path: 'documents',
    children: [
      {
        path: '/',
        element: <BorrowerDocuments />,
      },
      {
        path: '*',
        element: <Navigate to={LOGIN_REDIRECT_PATH} />,
      },
    ],
  },
  {
    index: true,
    element: <Navigate to={LOGIN_REDIRECT_PATH} />,
  },
  {
    path: '*',
    element: <Navigate to={LOGIN_REDIRECT_PATH} />,
  },
];

const installerRoutes = [
  {
    path: 'resources',
    element: <ResourcesLayout />,
    children: [
      {
        path: 'rate-sheets',
        element: <RateSheets />,
      },
      {
        path: 'marketing-materials',
        element: <MarketingMaterials />,
      },
    ],
  },
  {
    path: 'loan-calculator',
    children: [
      {
        index: true,
        element: <LoanCalculator />,
      },
    ],
  },
  {
    path: 'price-a-project',
    children: [
      {
        index: true,
        element: <PriceMyProjectPage />,
      },
    ],
  },
  ...(IS_DEV_OR_STAGING
    ? [
        {
          path: 'price-a-project-v1',
          children: [
            {
              index: true,
              element: <PriceMyProjectPageV1 />,
            },
          ],
        },
      ]
    : []),
  {
    path: 'loan-details',
    children: [
      {
        index: true,
        element: <LoanDetailsPage />,
      },
    ],
  },
  {
    path: 'borrowers',
    children: [
      {
        index: true,
        element: <BorrowerBrowse />,
      },
      {
        path: NEW_APPLICATION_PATH,
        element: <NewBorrowerForm />,
      },
      {
        path: '*',
        element: <Navigate to={LOGIN_REDIRECT_PATH} />,
      },
    ],
  },
  {
    path: 'projects/*',
    children: [
      {
        path: ':projectId',
        element: <ProjectDetails />,
      },
    ],
  },
  {
    path: 'change-orders',
    children: [
      {
        path: ':changeOrderId',
        element: <ChangeOrderLoader />,
        children: [
          {
            index: true,
            element: <ChangeOrder />,
          },
          {
            path: '*',
            element: <Navigate to={LOGIN_REDIRECT_PATH} />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={LOGIN_REDIRECT_PATH} />,
  },
  {
    index: true,
    element: <Navigate to={LOGIN_REDIRECT_PATH} />,
  },
];

export function getUserRoutes(user) {
  let dashboardRoutes;
  if (user?.record_type === USER_RECORD_TYPES.BORROWER) {
    dashboardRoutes = borrowerRoutes;
  } else if (
    [ACCOUNT_TYPES.INSTALLER, ACCOUNT_TYPES.CONSULTANT].includes(
      user?.account?.type
    )
  ) {
    dashboardRoutes = installerRoutes;
  } else {
    dashboardRoutes = [];
  }

  return [
    ...commonRoutes,
    {
      path: 'dashboard/*',
      element: (
        <AuthGuard>
          <ApplicationLayout />
        </AuthGuard>
      ),
      children: dashboardRoutes,
    },
  ];
}
