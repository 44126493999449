import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  InputLabelProps,
  InputBaseProps,
  OutlinedInput,
  OutlinedInputProps,
  Select,
  SelectProps,
} from '@mui/material';
import type { DistributiveOmit } from '@mui/types';
import { CustomTooltip } from '../CustomTooltip';
import { InfoIcon } from '../loan_application/icons';
import React, { ReactNode } from 'react';

export interface StyledInputProps
  extends DistributiveOmit<
    FormControlProps,
    'onBlur' | 'onFocus' | 'onChange' | 'defaultValue'
  > {
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  helperText?: ReactNode;
  warningText?: ReactNode;
  InputLabelProps?: Partial<InputLabelProps>;
  InputProps?: Partial<OutlinedInputProps>;
  SelectProps?: SelectProps;
  select?: boolean;
  autoComplete?: string;
  defaultValue?: unknown;
  autoFocus?: boolean;
  readOnly?: boolean;
  inputProps?: InputBaseProps['inputProps'];
  inputRef?: React.Ref<any>;
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value?: unknown;
  label?: React.ReactNode;
  /**
   * If `true`, a `textarea` element is rendered instead of an input.
   * @default false
   */
  multiline?: boolean;
  name?: string;
  onBlur?: OutlinedInputProps['onBlur'];
  onFocus?: OutlinedInputProps['onFocus'];
  onChange?: OutlinedInputProps['onChange'];
  tooltip?: ReactNode;
  additionalInfo?: ReactNode;
  labelCTA?: ReactNode;
  type?: React.InputHTMLAttributes<unknown>['type'];
  /**
   * Number of rows to display when multiline option is set to true.
   */
  rows?: string | number;
  /**
   * Maximum number of rows to display when multiline option is set to true.
   */
  maxRows?: string | number;
  /**
   * Minimum number of rows to display when multiline option is set to true.
   */
  minRows?: string | number;
  /**
   * Allows selection of multiple items
   */
  multiple?: boolean;
}
const StyledInput = React.forwardRef<HTMLDivElement, StyledInputProps>(
  function StyledInput(props, ref) {
    const {
      autoComplete,
      autoFocus = false,
      children,
      color = 'primary',
      defaultValue,
      disabled = false,
      error = false,
      FormHelperTextProps,
      fullWidth = false,
      helperText,
      id,
      InputLabelProps = {},
      inputProps,
      InputProps,
      inputRef,
      label,
      maxRows,
      minRows,
      multiline = false,
      multiple,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      required = false,
      rows,
      select = false,
      SelectProps,
      tooltip,
      additionalInfo,
      labelCTA,
      type,
      value,
      variant = 'outlined',
      warningText,
      ...other
    } = props;
    const InputMore: OutlinedInputProps = { notched: false };
    if (select) {
      // unset defaults from textbox inputs
      if (!SelectProps || !SelectProps.native) {
        InputMore.id = undefined;
      }
      InputMore['aria-describedby'] = undefined;
    }

    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const warningTextId = warningText && id ? `${id}-warning-text` : undefined;
    const additionalInfoId =
      additionalInfo && id ? `${id}-additional-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;
    const InputElement = (
      <OutlinedInput
        aria-describedby={helperTextId}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        fullWidth={fullWidth}
        multiline={multiline}
        name={name}
        rows={rows}
        maxRows={maxRows}
        minRows={minRows}
        type={type}
        value={value}
        id={id}
        inputRef={inputRef}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        inputProps={inputProps}
        {...InputMore}
        {...InputProps}
      />
    );
    return (
      <FormControl
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        ref={ref}
        required={required}
        color={color}
        variant={variant}
        {...other}
      >
        {label && (
          <FormLabel
            htmlFor={id}
            id={inputLabelId}
            {...InputLabelProps}
            sx={{
              mb: 1.25,
              ...InputLabelProps?.sx,
            }}
          >
            {label}
            {tooltip && (
              <CustomTooltip title={tooltip}>
                <InfoIcon
                  sx={{
                    fontSize: '14px',
                    mx: '.25rem',
                    verticalAlign: '-.15rem',
                  }}
                />
              </CustomTooltip>
            )}
            {labelCTA}
          </FormLabel>
        )}
        {additionalInfo && (
          <FormHelperText
            id={additionalInfoId}
            sx={{
              max: 0,
              color: 'black',
              bgcolor: '#F4F4F4',
              p: '0.5em',
              borderRadius: '5px',
              mb: '1em',
              ml: '0',
            }}
            error={false}
          >
            {additionalInfo}
          </FormHelperText>
        )}
        {select ? (
          <Select
            aria-describedby={helperTextId}
            id={id}
            labelId={inputLabelId}
            value={value}
            input={InputElement}
            multiple={multiple}
            {...SelectProps}
          >
            {children}
          </Select>
        ) : (
          InputElement
        )}
        {helperText && (
          <FormHelperText
            id={helperTextId}
            sx={{ mx: 0 }}
            {...FormHelperTextProps}
          >
            {helperText}
          </FormHelperText>
        )}
        {warningText && (
          <FormHelperText
            id={warningTextId}
            sx={{ mx: 0, color: '#E1AD01' }}
            error={false}
          >
            {warningText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default StyledInput;
