import { Switch, Box, Container, Grid, Typography } from '@mui/material';
import { useState } from 'react';

export type FeatureFlag =
  | 'showLTTMessaging'
  | 'showPlaidLink'
  | 'useDevPlaid'
  | 'showLoanCalculator'
  | 'showInstallerResources'
  | 'showInstallerRateSheets'
  | 'showPricingCalculator'
  | 'showUtilityAPIIntegration'
  | 'showSolarEconCalculator'
  | 'enablePricingCalculatorV1';

export type FeatureFlags = { [flag in FeatureFlag]: flag };

/**
 * Feature flags default values
 */
export const FEATURE_FLAG_DEFAULTS: { [flag in FeatureFlag]: 'on' | 'off' } = {
  showLTTMessaging: 'off',
  showPlaidLink: 'on',
  useDevPlaid: 'off',
  showLoanCalculator: 'off',
  showInstallerResources: 'on',
  showInstallerRateSheets: 'on',
  showPricingCalculator: 'on',
  showUtilityAPIIntegration: 'on',
  showSolarEconCalculator: 'on',
  enablePricingCalculatorV1: 'off',
};

export const FEATURE_FLAGS: FeatureFlags = {
  showLTTMessaging: 'showLTTMessaging',
  showPlaidLink: 'showPlaidLink',
  useDevPlaid: 'useDevPlaid',
  showLoanCalculator: 'showLoanCalculator',
  showInstallerResources: 'showInstallerResources',
  showInstallerRateSheets: 'showInstallerRateSheets',
  showPricingCalculator: 'showPricingCalculator',
  showUtilityAPIIntegration: 'showUtilityAPIIntegration',
  showSolarEconCalculator: 'showSolarEconCalculator',
  enablePricingCalculatorV1: 'enablePricingCalculatorV1',
};

export const DEBUG_SETTINGS: { [flag in FeatureFlag]: { title: string } } = {
  [FEATURE_FLAGS.showLTTMessaging]: {
    title: 'Show Loan Timeline Tracker user communication messaging',
  },
  [FEATURE_FLAGS.showPlaidLink]: {
    title: 'Show Plaid Link in Stage 2 Document Upload step',
  },
  [FEATURE_FLAGS.useDevPlaid]: {
    title: 'Use Development Plaid Environment',
  },
  [FEATURE_FLAGS.showLoanCalculator]: {
    title: 'Show Loan Calculator',
  },
  [FEATURE_FLAGS.showInstallerResources]: {
    title: 'Show Installer Resources',
  },
  [FEATURE_FLAGS.showInstallerRateSheets]: {
    title: 'Show Installer Rate Sheets',
  },
  [FEATURE_FLAGS.showPricingCalculator]: {
    title: 'Show Pricing Calculator',
  },
  [FEATURE_FLAGS.showUtilityAPIIntegration]: {
    title: 'Show Utility API Integration',
  },
  [FEATURE_FLAGS.showSolarEconCalculator]: {
    title:
      'Show Solar Econ Calculator on Price a Project Page and Initialize Application Page',
  },
  [FEATURE_FLAGS.enablePricingCalculatorV1]: {
    title: 'Enable Pricing Calculator V1',
  },
};

export const IS_DEV_OR_STAGING =
  process.env.NODE_ENV === 'development' ||
  process.env.INPUT_ENVIRONMENT === 'staging';

export const isFeatureEnabled = (featureFlag: FeatureFlag): boolean => {
  const debugOptions = JSON.parse(localStorage.getItem('debugOptions') || '{}');

  return (
    (debugOptions[featureFlag] ?? FEATURE_FLAG_DEFAULTS[featureFlag]) === 'on'
  );
};

export const DebugForm = () => {
  const [debugOptions, setDebugOptions] = useState(
    JSON.parse(localStorage.getItem('debugOptions') || '{}')
  );

  return (
    <Box pt={6.25} pb={3}>
      <Container maxWidth="md">
        <Grid container rowSpacing={4}>
          <Grid item xs={12}>
            <Typography variant="h1">Debug</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              border="none"
              px={3}
              py={3}
              bgcolor="background.paper"
              borderRadius="1.5px"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="100%"
              >
                {Object.keys(DEBUG_SETTINGS).map((settingKey) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    key={settingKey}
                    my={1}
                  >
                    <div>{DEBUG_SETTINGS[settingKey].title}</div>
                    <Switch
                      checked={debugOptions[settingKey] === 'on'}
                      onChange={(e) => {
                        const newOptions = {
                          ...debugOptions,
                          [settingKey]: e.target.checked ? 'on' : 'off',
                        };
                        localStorage.setItem(
                          'debugOptions',
                          JSON.stringify(newOptions)
                        );
                        setDebugOptions(newOptions);
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
