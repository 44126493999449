import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
  Control,
  Controller,
  UseFormSetError,
  useController,
  useFieldArray,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { InitialValues, timelineEstimations } from '../schema';
import NewBorrowerFormFieldset from '../NewBorrowerFormFieldset';
import {
  Alert,
  AlertColor,
  Box,
  CircularProgress,
  DialogContentText,
  Grid,
  Typography,
} from '@mui/material';
import SelectField from '../../../../hook_form_fields/SelectField';
import {
  CO_UTILITIES,
  IL_UTILITIES,
  MA_TERRITORIES,
  UNITED_STATES,
  maxFileSizeInBytes,
} from '../../../../../constants';
import { SolarProposalFileDropzone } from '../NewBorrowerForm';
import CheckboxField from '../../../../hook_form_fields/CheckboxField';
import InputField from '../../../../hook_form_fields/InputField';
import FileInput from '../FileInput';
import { DocumentIcon, InfoIcon } from '../../../../loan_application/icons';
import FileDropzone from '../../../../FileDropzone';
import { SunstoneDocument } from '../../../../../../../../types/api';
import { useAuth, useLabelWithTooltip } from '../../../../../hooks';
import { CustomTooltip } from '../../../../CustomTooltip';
import bytesToSize from '../../../../../utils/bytesToSize';
import NumberField from '../../../../hook_form_fields/NumberField';
import { api } from '../../../../../services/apis';
import {
  FEATURE_FLAGS,
  isFeatureEnabled,
} from '../../../../account/userSettings/DebugForm';
import {
  getDSCRMessage,
  isSpecialState,
  isStateWithREC,
} from '../../../../../utils/helpers';

export const year1SolarSavingsText =
  'Use a design and proposal software like Energy Toolbase to accurately calculate Year 1 Total Solar Savings.';

const utilityBillAdditionalInfo =
  'Please upload complete utility bill(s) that show 12 months of usage for the system location property. Up to 12 documents may be uploaded if needed.';

const SystemMountingOtherValueInput: FC<{
  control: Control<InitialValues>;
}> = ({ control }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const mountingOther = useWatch({
    control,
    name: 'mounting.other',
  });
  const {
    field: { onChange },
  } = useController({
    control,
    name: 'mounting.otherValue',
  });
  useEffect(() => {
    if (mountingOther && inputRef.current) {
      inputRef.current.focus();
    }
    onChange(''); // reset the value to empty string when it's changed
  }, [mountingOther, onChange]);
  return (
    <InputField
      control={control}
      name="mounting.otherValue"
      ref={inputRef}
      label=""
      data-testid="otherValue"
      id="mounting.otherValue"
      touched={true}
      isDisabled={!mountingOther}
      inputProps={{ style: { height: '8px' } }}
      sx={{ paddingTop: '6px', position: 'relative' }}
    />
  );
};

const showSolarEconCalculator = isFeatureEnabled(
  FEATURE_FLAGS.showSolarEconCalculator
);

const SolarProjectInformation: FC<{
  control: Control<InitialValues>;
  setError: UseFormSetError<InitialValues>;
  setValue: UseFormSetValue<InitialValues>;
}> = ({ control, setError, setValue }) => {
  const { touchedFields } = useFormState({ control });

  // e.g. '1020' -> 10
  const loanTerm = parseInt(control._formValues.loanTerm?.slice(0, 2));

  const propertyState = useWatch({
    control,
    name: 'application.sys_prop_address_state',
  });

  const previousPropertyState = useRef({ propertyState });
  // Reset the rec_info value when the property state changes
  useEffect(() => {
    if (previousPropertyState.current.propertyState !== propertyState) {
      setValue('application.rec_info', null, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    previousPropertyState.current = { propertyState };
  }, [propertyState, setValue]);

  const [
    loanAmount,
    y1saving,
    y1production,
    systemLocation,
    systemSize,
    recInfo,
    isSpecial,
    installerProductId,
  ] = useWatch({
    control,
    name: [
      'application.loan_amount',
      'application.year_1_total_solar_savings',
      'application.year_1_system_production_kwh',
      'application.sys_prop_address_state',
      'application.system_size_in_k_ws',
      'application.rec_info',
      'application.is_special',
      'application.loan_product',
    ],
  });
  const { account } = useAuth();

  const [interestRate, setInterestRate] = useState(0);
  const [productId, setProductId] = useState('');

  useEffect(() => {
    const installerProduct = account?.products.find(
      (p) => p.id === installerProductId
    );
    setInterestRate(installerProduct?.product.apr_percentage ?? 0);
    setProductId(installerProduct?.product.id ?? '');
  }, [
    account?.products,
    interestRate,
    isSpecial,
    loanAmount,
    installerProductId,
  ]);

  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('Alert');
  const [alertType, setAlertType] = useState('warning' as AlertColor);

  let timerId;
  const prevTimerId = useRef(timerId);
  const sendSolarRequest = useCallback(() => {
    return () => {
      clearTimeout(prevTimerId.current);
      prevTimerId.current = setTimeout(() => {
        setShowLoading(true);
        setShowAlert(false);
        api()
          .get(
            `solar-economics?amount=${loanAmount}&productId=${productId}&y1saving=${y1saving}&systemLocation=${systemLocation}` +
              (y1production ? `&y1production=${y1production}` : '') +
              (systemSize ? `&systemSize=${systemSize}` : '') +
              (recInfo ? `&recInfo=${recInfo}` : '')
          )
          .then((result) => {
            const data = JSON.parse(result.data.join(''));

            setShowLoading(false);
            setShowAlert(true);

            const [alertType, message] = getDSCRMessage(
              data.solarDSCR,
              loanTerm,
              account,
              systemLocation
            );
            setAlertType(alertType);
            setAlertContent(message);
          })
          .catch((err) => {
            const data = err?.response?.data;
            setShowLoading(false);
            setAlertContent(
              data.message ??
                'Error occurred while attempting to calculate the solar economics'
            );
            setAlertType('error');
            setShowAlert(true);
          });
      }, 500);
    };
  }, [
    account,
    productId,
    loanAmount,
    recInfo,
    systemLocation,
    systemSize,
    y1production,
    y1saving,
  ]);

  const previousStateInfo = useRef({
    systemLocation,
    y1production,
    systemSize,
    recInfo,
  });

  useEffect(() => {
    if (!showSolarEconCalculator) return;
    setAlertContent('');
    setShowAlert(false);
    if (
      loanAmount &&
      Number(loanAmount) >= 0 &&
      interestRate &&
      interestRate >= 0 &&
      systemLocation &&
      y1saving &&
      Number(y1saving) >= 0
    ) {
      // If recInfo changes, assign it to the previous value
      if (previousStateInfo.current.recInfo !== recInfo) {
        previousStateInfo.current.recInfo = recInfo;
      }

      if (isStateWithREC(systemLocation) && (!y1production || !systemSize)) {
        return;
      }

      if (isSpecialState(systemLocation) && !recInfo) {
        return;
      }
      setShowLoading(true);
      // With a short delay send the request
      sendSolarRequest()();
    }
  }, [
    loanAmount,
    interestRate,
    y1production,
    y1saving,
    systemLocation,
    systemSize,
    recInfo,
    sendSolarRequest,
    setValue,
  ]);

  return (
    <NewBorrowerFormFieldset title="Solar Project Information">
      <Grid container maxWidth="md" spacing={2}>
        <Grid item xs={12} sm={12}>
          <SelectField
            fullWidth
            control={control}
            name="application.sys_prop_address_state"
            label="Property's State where the system will be installed"
            required
            choices={UNITED_STATES}
          />
        </Grid>
        {['Colorado', 'Illinois', 'Massachusetts'].includes(propertyState) && (
          <Grid item xs={12} sm={12}>
            <SelectField
              fullWidth
              control={control}
              name="application.rec_info"
              label={
                propertyState == 'Massachusetts'
                  ? 'Utility Territory'
                  : 'Utility Company'
              }
              required={['Colorado', 'Illinois', 'Massachusetts'].includes(
                propertyState
              )}
              choices={
                propertyState == 'Colorado'
                  ? CO_UTILITIES
                  : propertyState == 'Illinois'
                  ? IL_UTILITIES
                  : MA_TERRITORIES
              }
              tooltip={
                propertyState == 'Massachusetts'
                  ? 'The utility territory is based on the area of the state where the solar system resides.'
                  : 'The utility company that will be used to power the solar system.'
              }
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <SelectField
            fullWidth
            control={control}
            name="application.project_timeline_estimation"
            label="Project Timeline Estimation"
            required
            choices={timelineEstimations}
            tooltip="Please indicate your best estimate at this time, it is ok if this information changes later."
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SolarProposalFileDropzone
            control={control}
            setError={setError}
            name="loan_proposal"
            required
            label="Solar Proposal Document"
            tooltip="The proposal should include the system size, system cost, year one production estimate, and all benefits the borrower will receive from the system, such as utility savings, ITC, and depreciation."
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <NumberField
            thousandSeparator
            decimalScale={3}
            control={control}
            name="application.system_size_in_k_ws"
            data-testid="system_size_in_k_ws"
            label="System Size in kWs"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <NumberField
            thousandSeparator
            decimalScale={3}
            control={control}
            name="application.year_1_system_production_kwh"
            data-testid="year_1_system_production_kwh"
            label="Year 1 Solar System Production (kWh)"
            tooltip="The year 1 solar system production in kilowatt-hours"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <NumberField
            thousandSeparator
            decimalScale={2}
            startAdornmentText="$"
            control={control}
            name="application.year_1_total_solar_savings"
            data-testid="year_1_total_solar_savings"
            label="Total Year 1 Utility Bill Savings"
            tooltip="Enter the total year 1 utility bill savings you expect the customer to realize, plus any year 1 revenue from sale of power that the system is expected to generate. Exclude solar incentives such as RECs, depreciation benefits, the Investment Tax Credit, and other one-time grants or tax breaks. Sunstone will add these based on the state. Only include demand charge savings in the utility bill savings if calculated using time-interval data from tools like Energy Toolbase."
            additionalInfo={year1SolarSavingsText}
            required
            fullWidth
          />
        </Grid>
        {showSolarEconCalculator && (
          <Grid item xs={12} sm={12}>
            <Box
              sx={{ maxWidth: '45px', margin: 'auto' }}
              display={showLoading ? 'block' : 'none'}
            >
              <CircularProgress />
            </Box>
            <Box display={showAlert ? 'block' : 'none'}>
              <Alert
                variant="outlined"
                severity={alertType}
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 0.5,
                  borderWidth: 2,
                  fontSize: 16,
                }}
              >
                {alertContent}
              </Alert>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <NumberField
            thousandSeparator
            startAdornmentText="$"
            decimalScale={2}
            control={control}
            name="application.total_system_cost"
            data-testid="total_system_cost"
            label="Total Solar-only System Cost (including Dealer Fee)"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="body2">
            System Mounting Hardware
            <Typography variant="body2" color="error" component="span">
              {' '}
              *
            </Typography>
          </Typography>
          <Controller
            control={control}
            name="mountingChecked"
            render={({ fieldState }) => {
              const { error } = fieldState;
              const mountingTouched = touchedFields['mounting'];
              const anyMountingCheckboxTouched =
                mountingTouched?.['roof'] ||
                mountingTouched?.['carport'] ||
                mountingTouched?.['ground'] ||
                mountingTouched?.['other'];

              return error && anyMountingCheckboxTouched ? (
                <Typography variant="body2" color="error" component="span">
                  {error.message}
                </Typography>
              ) : (
                <></>
              );
            }}
          />
          <Grid container spacing={0}>
            <Grid item xs={6} sm={6}>
              <CheckboxField
                control={control}
                name="mounting.roof"
                color="primary"
                label="Roof Mount"
                data-testid="roof"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <CheckboxField
                control={control}
                name="mounting.carport"
                color="primary"
                label="Carport"
                data-testid="carport"
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6} sm={6}>
              <CheckboxField
                control={control}
                name="mounting.ground"
                color="primary"
                label="Ground Mount"
                data-testid="ground"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box sx={{ display: 'flex', justifyItems: 'start' }}>
                <CheckboxField
                  control={control}
                  name="mounting.other"
                  color="primary"
                  label="Other:"
                  data-testid="other"
                />
                <SystemMountingOtherValueInput control={control} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DocumentUploadFieldset
            control={control}
            label="Utility Bills"
            required
            uploadMoreText="+ Add Additional Utility Bill (optional)"
            additionalInfo={utilityBillAdditionalInfo}
            max={12}
            name={'utility_bills'}
          />
        </Grid>
        <Grid item xs={12}>
          <DocumentUploadFieldset
            control={control}
            label={
              <Box>
                <Box display="flex">
                  <Typography variant="body1">
                    Supporting documents for state or local incentives
                    (Optional)
                  </Typography>
                  <CustomTooltip
                    title={
                      'Examples of such incentives include among others, grants, REC programs, utility incentives, and state or local tax credits.'
                    }
                  >
                    <InfoIcon
                      sx={{
                        fontSize: '.75rem',
                        marginTop: '.3125rem',
                        marginLeft: '.25rem',
                        verticalAlign: 'center',
                      }}
                    />
                  </CustomTooltip>
                </Box>
                <DialogContentText>
                  If the solar system is eligible for state or local incentives,
                  please upload supporting documents for the applicability of
                  such incentives. While not required to initiate an
                  application, such documentation may be required to complete
                  underwriting if the incentive is significant. Providing this
                  documentation now will shorten the underwriting process.
                </DialogContentText>
              </Box>
            }
            uploadMoreText="+ Add Additional Supporting Document"
            max={5}
            name={'incentive_documents'}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputField
            control={control}
            name="application.deal_description_and_context"
            multiline
            minRows={2}
            label={
              <Box>
                <Box display="flex">
                  <Typography>
                    Deal Description and Context (Optional)
                  </Typography>
                </Box>
                <DialogContentText>
                  Please provide any additional information that will help the
                  underwriting team understand the proposed project. Examples of
                  helpful information include who will ultimately use the power
                  (the host, one or multiple offtakers, community solar
                  subscribers, etc.), whether multiple or unique properties are
                  involved, any complicating factors you are aware of in the
                  borrower&apos;s ownership structure or business model, any
                  special state or local benefits that may be available, an
                  explanation if the proposed system production exceeds the
                  borrower&apos;s prior year energy usage, a status update if
                  the project has already begun construction, and any other
                  additional context you wish to provide that may streamline
                  underwriting.
                </DialogContentText>
              </Box>
            }
            maxLength={5000}
            counter
          />
        </Grid>
      </Grid>
    </NewBorrowerFormFieldset>
  );
};

export default SolarProjectInformation;

type DocumentUploadFieldsetProps = {
  label: ReactNode;
  name: keyof Pick<InitialValues, 'utility_bills' | 'incentive_documents'>;
  max: number;
  required?: boolean;
  tooltip?: string;
  infoText?: ReactNode;
  uploadMoreText?: ReactNode;
  additionalInfo?: string;
  control: Control<InitialValues>;
};

const DocumentUploadFieldset: FC<DocumentUploadFieldsetProps> = ({
  label,
  name,
  max,
  tooltip,
  required,
  uploadMoreText,
  additionalInfo,
  control,
}) => {
  const arrayHelpers = useFieldArray({
    control,
    name: name,
  });

  const { fields } = arrayHelpers;
  const [fileError, setFileError] = useState<string | null>(null);

  const labelWithTooltip = useLabelWithTooltip(label, tooltip);

  return (
    <Box component={'fieldset'} border={'none'} p={0} m={0}>
      {fields.map((field, index) => (
        <FileDropzone
          tooltip={tooltip}
          required={required}
          label={index === 0 ? label : null}
          remove={() => arrayHelpers.remove(index)}
          onDrop={(acceptedFiles, rejections) => {
            arrayHelpers.update(index, {
              file: acceptedFiles[0],
            });
          }}
          canReplace
          accept={{
            'image/*': [],
            'application/pdf': [],
            'application/doc': ['.doc', '.docx'],
            'application/text': ['.txt'],
          }}
          maxSize={maxFileSizeInBytes}
          file={
            {
              name: field.file?.name || '',
              display_name: field.file?.name || '',
            } as SunstoneDocument
          }
          hideMaxSize
          key={field.id}
          additionalInfo={additionalInfo}
        />
      ))}
      {fields.length < max && (
        <FileInput
          required={fields.length ? undefined : required}
          data-testid={`${name}-input`}
          key={fields.length + 'new'}
          label={fields.length ? null : labelWithTooltip}
          buttonContent={fields.length ? uploadMoreText : undefined}
          startAdornment={<DocumentIcon />}
          maxSize={maxFileSizeInBytes}
          error={!!fileError}
          errorMessage={fileError}
          onFileChange={(acceptedFiles, rejections) => {
            if (acceptedFiles.length) {
              arrayHelpers.append({
                file: acceptedFiles[0],
              });
              setFileError(null);
            } else if (rejections.length) {
              const file = rejections[0].file;
              const error = rejections[0].errors[0];
              let errorCopy: string;
              if (error.code === 'file-too-large') {
                errorCopy = `${file.name} is too big. Max size is ${bytesToSize(
                  maxFileSizeInBytes
                )}.`;
              } else if (error.code === 'file-invalid-type') {
                errorCopy = `${file.name} is not a valid file type. Should be an image, PDF, or a text document.`;
              } else {
                errorCopy = error.message;
              }
              setFileError(errorCopy);
            }
          }}
          accept={{
            'image/*': [],
            'application/pdf': [],
            'application/doc': ['.doc', '.docx'],
            'application/text': ['.txt'],
          }}
          additionalInfo={
            additionalInfo && fields.length === 0 ? additionalInfo : ''
          }
        />
      )}
    </Box>
  );
};
