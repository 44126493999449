declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _kmq: any[];
  }
}

export class Tracker {
  private static instance: Tracker;
  private constructor() {}

  /*    
    Event nomenclature goes a long way. 
    
    When adding new events, try to:

    - Use prefixes to groups related events. 
    - Understandable names. 
      app-table-tab-change vs 'nav-change'
    - Decouple from things that might change, CTA copy. 
      'application-begin' vs 'clicked-get-started' 
  */
  public static EVENTS = {
    // User and account
    LOGOUT: 'user-logout',
    LOGIN: 'user-login',
    FORGOT_PASSWORD: 'user-forgot-password',
    REGISTER_CLICKED: 'user-register-clicked',
    REGISTER_SUCCESS: 'user-register-success',
    REGISTER_ERROR: 'user-register-error',

    // New applicant invites
    NEW_APPLICANT_ERROR: 'new-applicant-error',
    NEW_APPLICANT_SUBMIT: 'new-applicant-submit',
    NEW_APPLICANT_CANCEL: 'new-applicant-cancel',
    NEW_APPLICANT_CTA_CLICKED: 'new-applicant-cta-clicked',
    NEW_APPLICANT_BACK_CLICKED: 'new-applicant-back-clicked',

    // Applications table
    APP_TABLE_NAV_CHANGE: 'app-table-tab-change',
    APP_TABLE_ROW_EXPAND: 'app-table-row-expand',
    APP_TABLE_ROW_COLLAPSE: 'app-table-row-collapse',

    // Pricing calculator
    PRICING_CALCULATOR_RESTART: 'pricing-calculator-restart',
    PRICING_CALCULATOR_SUBMIT: 'pricing-calculator-submit',
    PRICING_CALCULATOR_LOAN_TYPE_SELECTED:
      'pricing-calculator-loan-type-selected',
    PRICING_CALCULATOR_LOAN_TERM_SELECTED:
      'pricing-calculator-loan-term-selected',
    PRICING_CALCULATOR_INTEREST_RATE_SELECTED:
      'pricing-calculator-interest-rate-selected',
    PRICING_CALCULATOR_ENTERED_NETFUNDING_VALUE_ENTERED:
      'pricing-calculator-entered-netfunding-value-entered',
    PRICING_CALCULATOR_ENTERED_LOANAMOUNT_VALUE_ENTERED:
      'pricing-calculator-entered-loanamount-value-entered',

    // Prequalify calc section events
    PREQUALIFY_CALC_SOLARSYSTEMLOCATION_VALUE_ENTERED:
      'prequalify-calc-solar-system-location-value-entered',
    PREQUALIFY_CALC_UTILITYBILLSAVINGS_VALUE_ENTERED:
      'prequalify-calc-utilitybill-savings-value-entered',
    PREQUALIFY_CALC_YEAR1SOLARPRODUCTION_VALUE_ENTERED:
      'prequalify-calc-year-1-solar-production-value-entered',
    PREQUALIFY_CALC_SOLARSYSTEMSIZE_VALUE_ENTERED:
      'prequalify-calc-solar-system-size-value-entered',
    PREQUALIFY_CALC_UTILITYCOMPANY_VALUE_ENTERED:
      'prequalify-calc-utility-company-value-entered',
    PREQUALIFY_CALC_SUCCESS: 'prequalify-calc-success',
    PREQUALIFY_CALC_ERROR: 'prequalify-calc-error',

    // Quote page events
    QUOTE_DOWNLOAD_PDF_CLICKED: 'quote-download-pdf-clicked',
    QUOTE_INITIALIZE_APP_CLICKED: 'quote-initialize-app-clicked',
    QUOTE_VIEW_RATE_SHEETS_CLICKED: 'quote-view-rate-sheets-clicked',

    // Projects table
    PROJECT_TABLE_NAV_CHANGE: 'project-table-tab-change',
  };

  public static getInstance(): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker();
    }
    return Tracker.instance;
  }

  public track(event: string, properties?: Record<string, any>): void {
    const eventProperties = {
      ...properties,
      rosefieldVersion: __versionString__,
    };

    if (typeof window._kmq !== 'undefined') {
      window._kmq.push(['record', event, eventProperties]);
    } else {
      console.error('_kmq is not defined');
    }
  }

  public identify(email: string): void {
    if (typeof window._kmq !== 'undefined') {
      window._kmq.push(['identify', email]);
    } else {
      console.error('_kmq is not defined');
    }
  }
}
