import { NumberFormatValues } from 'react-number-format';
import { LIMITS, PG_COMPANY_TYPES } from '../../../constants';

// this determines if a loan requires a personal guarantor
export const updateIsPersonalGuarantor = (control) => {
  const fv = control._formValues;
  const loanSizeCriteria =
    fv.loan_amount && fv.loan_amount <= LIMITS.PG_LOAN_AMOUNT_CEILING;
  const power_company_type = fv.solar_system_power_company_type ?? '';

  // Operating Company, Property Company or Agriculture
  if (loanSizeCriteria && PG_COMPANY_TYPES.includes(power_company_type)) {
    fv.is_personal_guarantor = 'Yes';
  } else {
    fv.is_personal_guarantor = 'No';
  }

  return fv.is_personal_guarantor;
};

export const calculatePGPercentOwnership = (principals): number => {
  // only relevant for PG loans
  let pgPercentOwnership = 0;
  for (const principal of principals) {
    // only relevant if principal is a PG
    if (
      principal.principal_is_personal_guarantor === 'Yes' &&
      principal.percent_ownership
    ) {
      pgPercentOwnership += parseFloat(principal.percent_ownership);
    }
  }
  return parseFloat(pgPercentOwnership.toFixed(2));
};

export const countPGs = (principals): number => {
  return principals.filter(
    (principal) => principal.principal_is_personal_guarantor === 'Yes'
  ).length;
};

export const isValidSFCurrency = (values: NumberFormatValues): boolean => {
  const { floatValue } = values;
  if (floatValue) return floatValue >= 0 && floatValue <= 10000000000000000;
  return true;
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export const shortenFilename = (name: string, maxLength: number) => {
  if (name.length <= maxLength) {
    return name;
  }
  return name.substring(0, maxLength - 3) + '...';
};
