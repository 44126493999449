import { Box, Container, Grid, Link, SvgIcon, Typography } from '@mui/material';
import { ChangePasswordForm } from './ChangePasswordForm';
import { UserProfileForm } from './UserProfileForm';
import { BankingForm } from './BankingForm';
import AttentionRequired from '../../../../../frontend/static/error/AttentionRequired.svg';
import { INSTALLER_TYPES, FIN_OPS_EMAIL } from '../../../constants';
import { useAuth } from '../../../hooks';
import { isConsultant, isInstaller } from '../../../utils/helpers';

const UserSettingsFormContainer = ({ children }) => {
  return (
    <Box
      border="none"
      px={3}
      py={4}
      bgcolor="background.paper"
      borderRadius={0.75}
    >
      {children}
    </Box>
  );
};

export const UserSettings = () => {
  const { user, account } = useAuth();
  const missingBankInfo = !(
    account.installer_bank_account_name &&
    account.installer_bank_account_number &&
    account.installer_bank_routing_number &&
    account.installer_bank_name &&
    account.location_of_bank
  );

  const isInstallerAccount =
    isInstaller(user) &&
    (user.installer_role === INSTALLER_TYPES.ADMIN ||
      user.installer_role === INSTALLER_TYPES.ACCOUNTING);
  const isConsultantAccount = isConsultant(user);
  const showBankingInfo = isInstallerAccount || isConsultantAccount;

  return (
    <Box pt={6.25} pb={3}>
      <Container maxWidth="xs" sx={{ marginBottom: '12px', marginLeft: '0px' }}>
        {missingBankInfo && showBankingInfo && (
          <Box
            display="inline-flex"
            alignItems="center"
            bgcolor="white"
            p={1.25}
            borderRadius={0.75}
            mb={2.5}
          >
            <SvgIcon component={AttentionRequired} />
            <Typography variant="body1" sx={{ paddingLeft: '8px' }}>
              <b>Action Required:</b> Please add your{' '}
              <Link
                href="#bankingInfo"
                sx={{
                  textDecoration: 'none',
                }}
              >
                bank information.
              </Link>
            </Typography>
          </Box>
        )}
      </Container>
      <Container maxWidth="md">
        <Grid container rowSpacing={4}>
          <Grid item xs={12}>
            <Typography variant="h1">Profile</Typography>
          </Grid>
          <Grid item xs={12}>
            <UserSettingsFormContainer>
              <UserProfileForm />
            </UserSettingsFormContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h1">Account</Typography>
          </Grid>
          <Grid item xs={12}>
            <UserSettingsFormContainer>
              <ChangePasswordForm />
            </UserSettingsFormContainer>
          </Grid>
          {showBankingInfo && (
            <Grid
              item
              xs={12}
              id="bankingInfo"
              sx={{ scrollMarginTop: '50px' }}
            >
              <Typography variant="h1">Banking Information</Typography>
            </Grid>
          )}
          {showBankingInfo && (
            <Grid item xs={12}>
              <UserSettingsFormContainer>
                <BankingForm />
              </UserSettingsFormContainer>
            </Grid>
          )}
          {showBankingInfo && !missingBankInfo && (
            <Typography
              variant="body1"
              sx={{ marginTop: '25px', flexGrow: 1, textAlign: 'center' }}
            >
              If you would like to make changes to your banking information,
              please contact{' '}
              <Link
                sx={{ textDecoration: 'none' }}
                href={`mailto:${FIN_OPS_EMAIL}`}
              >
                {FIN_OPS_EMAIL}
              </Link>
            </Typography>
          )}
        </Grid>
      </Container>
    </Box>
  );
};
